// General

*{font-family: 'Montserrat', sans-serif;}
*::selection{
  background: $primary;
  color: #ffffff;
}


// Button

.btn{
  font-weight: 700;
  border-radius: 0;
  font-size: 16px;
  padding-left: 40px;
  padding-right: 40px;
}
.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:focus{
  color: #fff !important;
}
.btn-secondary, .btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus, .btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary:focus{
  color: $primary !important;
}
.btn-outline-primary:hover, .btn-outline-primary:active{
  background: transparent !important;
}
.btn-primary.btn:disabled{
  border-color: #d7d7d7 !important;
  background-color: #d7d7d7 !important;
}
.bg-grey{
  background-color: $grey;
}
.icon-button{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: #FFF;
  background-color: rgba(255, 255, 255, .4) ;
  border-width: 0;
  font-size: 1.1rem;
  justify-content: center;
  align-items: center;
}

.bg-red{
  background-color: #DB504A;
}
.text-red{
  color: #DB504A;
}

// Text

h1{
  font-weight: 900;
  font-size: 40px;
  text-transform: uppercase;
  font-style: italic;
  text-align: center;

}
h2{
  font-weight: 600;
  font-size: 17px;
  color: #000000;
}
body{
  color: $info;
  font-size: 16px;
  background: #FFF;
  min-height: calc(100vh - 80px);
  background-color: $grey;
}
.label{
  font-size: 16px;
  color: #6B7888;
}
a{
  font-weight: 800;
  &:hover{
    color: $primary;
  }
}
.link-to-confirm{
  font-weight: 800;
  padding-left: 60px;
  color: $primary;
  text-decoration: underline;
  cursor: pointer;
}
.text-right{
  text-align: right;
}
.text-regular{
  color: #6B7888;
  font-size: 14px;
  font-weight: 400;
}
.fs-20{
  font-size: 20px;
}

// Form Select
.css-hzoui-control:hover{
  border-color: $primary !important;
}

// DatePicker
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range, .react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: $primary !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: $secondary !important;
  color: $primary !important;
}

// Switch

.switch{
  .form-check-input, .form-check-label{
    cursor: pointer;
  }
  .form-check-input{
    transform: scale(1.5);
    transform-origin: top left
  }
  .form-check-label{
    margin-left: 25px;
  }

}

// Calendar
.fc .fc-highlight{
  background: $primary;
}
/* Stile per disabilitare le celle precedenti alla data iniziale */
.fc-day.disabled-cell {
  pointer-events: none;
  background-color: red;
  color: #bbb;
}
.fc:hover{
  cursor: pointer;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(auto, auto);
}

.cursor-pointer{
  cursor: pointer;
}

.fc .fc-bg-event{
  background-color: #DB504A;
  pointer-events: none

}
@media (max-width: 767px) {
  /* Stili da applicare per dispositivi con larghezza massima di 767px */
  .wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

}

// Tost

.toast-container{
  position: fixed;
  box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.3);
  .toast{
    background-color: #FFF;
    .toast-header{
      background-color: transparent;
    }
    border: none;
    padding: 10px;
    .pointer{
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right:  10px;
      &.danger{
        background-color: $danger;
      }
      &.success{
        background-color: $success;
      }
    }
  }
}

// Loading

.container-loading {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.834);
  z-index: 1;
}

.spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 9px solid;
  border-color: #FFF3EB;
  border-right-color: $secondary;
  animation: spinner-d3wgkg 1s infinite linear;
}

@keyframes spinner-d3wgkg {
  to {
    transform: rotate(1turn);
  }
}


// Detail

.avatar-image{
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  border: solid #F1F1F1 2px;
  margin-top: -75px;
  background-color: #f8f9f9;
  .icon{
    width: 100%;
    height: 100%;
    color: #EBEBEA;
    font-size: 80px;
  }
}

.button-circle{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  &.status{
    position: absolute;
    right: 0;
    color: #FFFFFF;
    &.active{
      background-color: #B9D585;
    }
    &.deactivated{
      background-color: #F06464;
    }
    &.attention{
      background-color: #FFCA3A;
    }
  }
}

.qrcode-area{
  position: absolute;
  width: 150px;
  height: 150px;
  left: 50%;
  top: 20vh;
  margin-left: -75px;
  margin-top: -75px;
  z-index: 1;
  opacity: .2;
  background-image: url("../image/skipasslombardia.png");
  background-size: contain;
  mix-blend-mode: darken;
}








