/* The following block can be included in a custom.scss */

$primary: #007DB7;
$secondary: #E45A01;
$info: #092340;
$grey: #F8F9F9;

.btn-primary{
  color: #fff;
}

.btn-secondary{
  color: #007DB7;
}

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';
